@import 'foundation-settings';
@import 'variables';

// Main Colours
$sbv-schub: rgb(93, 126, 45); // #52801a
$sbv-dark-schub: rgb(35, 83, 0); // #235300
$sbv-schub-disabled: #9ebc7a;
$sbv-schub-cluster: #52801a;
$sbv-black: #000000;

$disabled-background: $sbv-schub-disabled;

$button-farmsearch-filter-background-color: $sbv-schub;
$button-farmsearch-filter-text-color: $white;

$button-farmsearch-filter-close-background-color: $sbv-schub;
$button-farmsearch-filter-close-text-color: $white;

$button-close-color: $sbv-black;

$nav-website-link-color: $sbv-schub;
$nav-website-link-hover-color: $sbv-dark-schub;

$top-bar-background-color: $sbv-schub;
$top-bar-color: $white;
$map-top-bar-bg-color: $sbv-schub;

$filter-summary-button-bg-color: $sbv-black;
$filter-close-button-bg-color: $sbv-schub;
$filter-reset-color: $sbv-schub;

$open-modal-bg-color: $dove-gray;

$search-title-color: $white;

// select
$select-focus-color: $sbv-schub;

// main navigation
$main-navigation-overlay-color: $sbv-schub;
$main-navigation-hover-color: $sbv-schub;
$main-navigation-active-color: $sbv-schub;

$arrow-svg: url('/static/assets/images/arrow-white.svg') !default;
$menu-svg: url('/static/assets/images/menu-white.svg') !default;

// farm
$farm-link-color: $sbv-schub;
$farm-link-color-hover: $sbv-dark-schub;

$search-place-link-color: $sbv-schub;
$search-place-link-color-hover: $sbv-dark-schub;

// not found
$not-found-link-color: $sbv-schub;

$cluster-color: $sbv-schub-cluster;

$input-prefix-color: $sbv-schub;

$button-palette: (
    primary: $sbv-schub,
    secondary: $sbv-yellow
);

$main-background-image-url: '/static/assets/images/header-images/schub.jpg' !default;

$map-link-color: $farm-link-color;
$map-link-hover-color: $farm-link-color-hover;

@import '../abstracts/schub-variables';

$height: 71px;
$width: 90px;

.logo-project--schub {
    background-repeat: no-repeat;
    background-size: $width $height;
    height: $height;
    width: $width;
}

.search-logo {
    background-size: $width $height;
    height: $height;
    width: $width;

    @include breakpoint (medium up) {
        background-size: calc(#{$width} * 1.5) calc(#{$height} * 1.5);
        height: calc(#{$height} * 1.5);
        width: calc(#{$width} * 1.5);
    }
}

.search-logo--de,
.logo-project--schub-de {
    background-image: url('/static/assets/images/logos/schub-de.jpg');
}

.search-logo--fr,
.logo-project--schub-fr {
    background-image: url('/static/assets/images/logos/schub-fr.jpg');
}

.search-logo--it,
.logo-project--schub-it {
    background-image: url('/static/assets/images/logos/schub-it.jpg');
}

.comp-hofsearch-map {
    .soldout-info-map {
        display: none;
    }
}

@import 'client/scss/main';
